import {
    memo,
    Fragment,
    Suspense,
    useState,
    useCallback,
    useEffect,
} from "react";
import { Container, Row } from "reactstrap";
import { Colxx } from "components/common/CustomBootstrap";
import AliceCarousel from "react-alice-carousel";
import { useSelector } from "react-redux";
import { urlImage } from "localUrl";
import { ImageAsyn } from "components/ImageAsyc";

const TextLeftSection = () => {
    const { entities } = useSelector(({ HomeApp }) => HomeApp.Promocion);
    const [promocion, setPromocion] = useState(null);

    const InitDatos = useCallback(() => {
        entities &&
            entities.length > 0 &&
            setPromocion(
                entities.map(({ promocion }) => (
                    <Fragment>
                        <Suspense
                            fallback={
                                <div style={{ height: "100vh" }}>
                                    <div className="loading" />
                                </div>
                            }
                        >
                            <ImageAsyn
                                src={`${urlImage}/files/promocion/${promocion.foto}`}
                                alt="otros"
                                className="sliderImage"
                            />
                        </Suspense>
                    </Fragment>
                ))
            );
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [entities]);
    useEffect(() => {
        InitDatos();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [InitDatos]);

    return (
        <Container>
            <Row>
                <Colxx md="6">
                    <div className="mad_section_container mad_testimonials">
                        <article>
                            <h3 className="mad_title_style1">Promociones</h3>
                            <h2>Nuestras Promociones</h2>

                            <div className="carousel_type_1">
                                <div className="owl-carousel">
                                    <div>
                                        <blockquote>
                                            <p className="text-lg">
                                                La lealtad tiene su
                                                recompensa!...
                                            </p>
                                            <div className="mad_author style2 ">
                                                <h3 className="mad_title_style2 mt-5">
                                                    Samuel Salguero
                                                </h3>
                                                <span> Estilista</span>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </Colxx>
                <Colxx md="6">
                    <figure className="section_image align_right w-100 h-100">
                        {promocion ? (
                            <div className="rev_slider_wrapper">
                                <div id="slider1" className="rev_slider">
                                    <AliceCarousel
                                        mouseTracking
                                        autoPlay={true}
                                        autoPlayStrategy="none"
                                        autoPlayInterval={3000}
                                        animationDuration={800}
                                        animationType="fadeout"
                                        infinite
                                        touchTracking={false}
                                        disableButtonsControls
                                        items={promocion}
                                    />
                                </div>
                            </div>
                        ) : (
                            <span className="fs-2 font-weight-bold">
                                SIN PROMOCIONES
                            </span>
                        )}
                    </figure>
                </Colxx>
            </Row>
        </Container>
    );
};

export default memo(TextLeftSection);
